var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-box"},[_c('head-main'),_c('div',{staticClass:"carousel-box"},[_c('div',{staticClass:"search-box"},[_c('el-autocomplete',{attrs:{"popper-class":"my-autocomplete","fetch-suggestions":_vm.querySearch,"placeholder":"请输入内容"},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.contentName))])]}}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('span',{staticClass:"search-span",attrs:{"slot":"suffix"},on:{"click":_vm.toSearchResult},slot:"suffix"},[_c('i',{staticClass:"el-icon-search el-input__icon"}),_vm._v("搜索 ")])])],1),_c('el-carousel',{attrs:{"interval":10000,"arrow":"never","height":_vm.screenHeight + 'px'}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{key:index,style:({
          backgroundImage: 'url(' + item.bannerImg + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        })})}),1)],1),_c('botto-com')],1)}
var staticRenderFns = []

export { render, staticRenderFns }