import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'cityMap'
  },
  {
    path: '/search',
    name: 'search',
    component: index,
    meta: {
      title: '非遗检索',
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/searchResult/:searchText?',
    name: 'searchResult',
    component: () => import(/* webpackChunkName: "searchResult" */ '../views/other/search.vue'),
    meta: {
      title: '非遗检索',
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/cityMap',
    name: 'cityMap',
    component: () => import(/* webpackChunkName: "cityMap" */ '../views/counties/cityMap.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/content/article',
    name: 'contentArticle',
    component: () => import(/* webpackChunkName: "pageContent" */ '../views/Information/pageContent.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/project/article',
    name: 'projectArticle',
    component: () => import(/* webpackChunkName: "pageContent" */ '../views/Information/pageContent.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/informationNew',
    name: 'informationNew',
    component: () => import(/* webpackChunkName: "informationNew" */ '../views/Information/informationNew.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/cityIndex',
    name: 'cityIndex',
    component: () => import(/* webpackChunkName: "cityIndex" */ '../views/counties/cityIndex.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/content/project',
    name: 'projectIndex',
    component: () => import(/* webpackChunkName: "projectIndex" */ '../views/index/projectIndex.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/content/inheritor',
    name: 'inheritorIndex',
    component: () => import(/* webpackChunkName: "inheritorIndex" */ '../views/index/inheritorIndex.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "live" */ '../views/other/live.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/regulations',
    name: 'regulations',
    component: () => import(/* webpackChunkName: "regulations" */ '../views/other/regulations.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/inheritor',
    name: 'Inheritor',
    component: () => import(/* webpackChunkName: "Inheritor" */ '../views/certification/Inheritor.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/certificatePassed',
    name: 'CertificatePassed',
    component: () => import(/* webpackChunkName: "CertificatePassed" */ '../views/certification/CertificatePassed.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import(/* webpackChunkName: "certificate" */ '../components/certificate.vue'),
    meta: {
      content: 'user-scalable=yes'
    }
  },
  {
    path: '/personindex',
    name: 'personindex',
    component: () => import(/* webpackChunkName: "personindex" */ '../views/personal/personindex.vue'),
    redirect: 'dynamic',
    meta: {
      content: 'user-scalable=yes'
    },
    children: [
      {
        path: '/myNew',
        name: 'myNew',
        component: () => import(/* webpackChunkName: "myNew" */ '../views/personal/myNew.vue')
      },
      {
        path: '/myConcerns',
        name: 'myConcerns',
        component: () => import(/* webpackChunkName: "myConcerns" */ '../views/personal/myConcerns.vue')
      },
      {
        path: '/myFans',
        name: 'myFans',
        component: () => import(/* webpackChunkName: "myFans" */ '../views/personal/myFans.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '../views/personal/message.vue')
      },
      {
        path: '/myinformation',
        name: 'myinformation',
        component: () => import(/* webpackChunkName: "myinformation" */ '../views/personal/myinformation.vue')
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        component: () => import(/* webpackChunkName: "dynamic" */ '../views/personal/dynamic.vue')
      },
      {
        path: '/mailInfo',
        name: 'mailInfo',
        component: () => import(/* webpackChunkName: "mailInfo" */ '../views/personal/mailInfo.vue')
      },
      {
        path: '/messageTwo',
        name: 'messageTwo',
        component: () => import('../views/personal/messageTwo.vue')
      },
      {
        path: '/sendMessage/:friendId?/:friendName?',
        name: 'sendMessage',
        component: () => import('../views/personal/sendMessage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
