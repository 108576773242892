<template>
  <div class="bottom-box">
    <div class="b-l">
      <i class="wecat-i"></i>
      <div class="b-l-content">
        <p>扫码关注官方微信公众号获取更多动态</p>
        <p>联系邮箱：npqzysg@163.com</p>
      </div>
    </div>
	<div class="b-r" style="margin-left: 22%;">
	  <i class="b-logo"></i>
	  <div class="b-l-content" >
	    <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #FFFFFF;"><p style="text-align: center;">闽ICP备2021002283号</p></a>
	    <p>版权所有 南平市非物质文化遗产保护中心</p>
	  </div>
	</div>
    <div class="b-c" style="">
      <ul class="link-ul">
        <li v-for="item in friendLink" :key="item.id" @click="toFriendLink(item)">{{ item.linkName }}</li>
      </ul>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['friendLink'])
  },
  mounted() {
    this.getFriendLink()
  },
  methods: {
    ...mapMutations(['setFriendLink']),
    getFriendLink() {
      if (this.friendLink.length === 0) {
        this.axios.get('/api/portal/site/friend-link', {}).then(res => {
          this.setFriendLink(res.data)
        })
      }
    },
    toFriendLink(friendLink) {
      window.open(friendLink.linkUrl)
    }
  }
}
</script>
