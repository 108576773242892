<template>
  <el-dialog
    top="20vh"
    class="el-dialog-cus"
    v-bind="attributes"
    :visible="visible"
    :before-close="beClose"
    append-to-body
    :close-on-click-modal="false"
    v-on="on"
  >
    <div class="pop-login-box">
      <el-link class="custon-close" @click="cancel"><i class="el-icon-close"></i></el-link>
      <div class="info-form">
        <div class="login-tab">
          <el-tabs v-model="activeName">
            <el-tab-pane label="登录" name="login">
              <div class="user-login-box" v-if="loginType === 'account'">
                <el-form
                  :model="loginFormAccount"
                  :rules="loginFormAccountRules"
                  ref="loginFormAccount"
                  label-width="80px"
                  class=""
                >
                  <el-form-item label="账号" prop="username">
                    <el-input v-model="loginFormAccount.username" placeholder="请输入用户"></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="password">
                    <el-input v-model="loginFormAccount.password" type="password" placeholder="请输入密码"></el-input>
                  </el-form-item>
                </el-form>
                <div class="change-login">
                  <span @click="forgetPwd">忘记密码</span>
                  <span @click="loginType = 'phone'">手机号登入</span>
                </div>
              </div>
              <div class="phone-login-box" v-else-if="loginType === 'phone'">
                <el-form
                  :model="loginFormPhone"
                  :rules="loginFormPhoneRules"
                  ref="loginFormPhone"
                  label-width="80px"
                  class=""
                >
                  <el-form-item label="手机号" prop="phone">
                    <el-input v-model="loginFormPhone.phone" placeholder="请输入手机号"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="captchaCode">
                    <el-input v-model="loginFormPhone.captchaCode" placeholder="请输入验证码">
                      <el-button
                        slot="suffix"
                        class="message-btn"
                        @click="getLoginCaptcha"
                        :disabled="loginCountdown !== 0"
                      >
                        <template v-if="loginCountdown === 0">发送验证码</template>
                        <template v-else>{{ loginCountdown }}s后重新发送</template>
                      </el-button>
                    </el-input>
                  </el-form-item>
                </el-form>
                <div class="change-login">
                  <span>忘记密码</span>
                  <span @click="loginType = 'account'">密码登入</span>
                </div>
              </div>
              <!-- <div class="btn-box"> -->
              <el-button
                class="form-btn"
                @click="submitForm(loginType === 'account' ? 'loginFormAccount' : 'loginFormPhone')"
                >登录
              </el-button>
              <!-- </div> -->
              <!-- <h5 class="wx-line">
                <span>微信扫码登录</span>
              </h5>
              <span
                class="wx-m"
                :style="{
                  backgroundImage: 'url(' + wx + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                }"
              ></span> -->
            </el-tab-pane>
            <el-tab-pane label="注册" name="register">
              <el-form
                :model="registerForm"
                :rules="registerFormRules"
                ref="registerForm"
                label-width="100px"
                class="zc-tbl"
              >
                <el-form-item label="账号" prop="account">
                  <el-input v-model="registerForm.account" placeholder="请输入用户"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="captchaCode">
                  <el-input v-model="registerForm.captchaCode" placeholder="请输入验证码">
                    <el-button
                      slot="suffix"
                      class="message-btn"
                      @click="getRegisterCaptcha"
                      :disabled="registerCountdown !== 0"
                    >
                      <template v-if="registerCountdown === 0">发送验证码</template>
                      <template v-else>{{ registerCountdown }}s后重新发送</template>
                    </el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="设置密码" prop="password">
                  <el-input v-model="registerForm.password" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <!-- <el-form-item>
                                    <el-button class="form-btn" @click="submitForm('ruleForm')">立即创建</el-button>                                 
                                </el-form-item> -->
              </el-form>
              <!-- <div class="change-login">
                <p><el-checkbox v-model="readAndAccept"></el-checkbox>我已阅读并接受<el-link>用户协议</el-link></p>
              </div> -->
              <el-button class="form-btn" @click="submitForm('registerForm')">立即创建</el-button>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <pop-reset-pwd ref="pwd"></pop-reset-pwd>
    <!-- <div slot="footer">
      <el-button @click="cancel" plain>{{btnTxt[0]}}</el-button>
      <el-button @click="confirm" type="primary" v-if="btnTxt[1]">{{btnTxt[1]}}</el-button>
    </div> -->
  </el-dialog>
</template>

<script>
import popResetPwd from '../components/popResetPwd'
import md5 from 'js-md5'
import qs from 'qs'

export default {
  inheritAttrs: false,
  props: {
    active: {
      type: String,
      default: 'login'
    },

    config: {
      type: Object,
      default: () => ({
        width: '420px',
        title: '提示',
        center: true,
        btnTxt: ['取消', '确定']
      })
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      readAndAccept: false,
      loginCountdown: 0,
      registerCountdown: 0,
      wx: require('../assets/images/wx-m.png'),
      loginType: 'account',
      activeName: 'login',
      visible: false,
      attributes: this.config,
      btnTxt: this.config.btnTxt,
      on: this.getDialogEvents(),
      registerForm: {
        account: '',
        phone: '',
        password: '',
        captchaKey: '',
        captchaCode: '',
        clientType: 1 // 1 PC, 2 微信
      },
      loginFormPhone: {
        grant_type: 'sms-member',
        scope: 'all',
        phone: '',
        captchaKey: '',
        captchaCode: ''
      },
      loginFormAccount: {
        grant_type: 'password-member',
        scope: 'all',
        username: '',
        password: ''
      },
      loginFormAccountRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loginFormPhoneRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        captchaCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      registerFormRules: {
        account: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        captchaCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  components: {
    popResetPwd
  },
  methods: {
    forgetPwd() {
      this.$refs.pwd.open()
    },
    reset() {
      this.loginFormPhone = { ...this.loginFormPhone, phone: '', captchaKey: '', captchaCode: '' }
      this.loginFormAccount = { ...this.loginFormAccount, username: '', password: '' }
    },
    getLoginCaptcha() {
      this.axios.get('/api/blade-auth/member/oauth/sms?mobile=' + this.loginFormPhone.phone).then(res => {
        this.$message.success('发送成功')
        this.loginFormPhone.captchaKey = res.data.key
        // this.loginFormPhone.captchaCode = res.data.code
        this.loginCountdown = 60
        let timer = setInterval(() => {
          this.loginCountdown--
          if (this.loginCountdown === 0) {
            clearInterval(timer)
          }
        }, 1000)
      })
    },
    getRegisterCaptcha() {
      this.axios.get('/api/portal/member/sms?mobile=' + this.registerForm.phone).then(res => {
        this.$message.success('发送成功')
        this.registerForm.captchaKey = res.data.key
        // this.registerForm.captchaCode = res.data.code
        this.registerCountdown = 60
        let timer = setInterval(() => {
          this.registerCountdown--
          if (this.registerCountdown === 0) {
            clearInterval(timer)
          }
        }, 1000)
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (formName === 'registerForm') {
            let registerForm = { ...this.registerForm }
            registerForm.password = md5(registerForm.password)
            let headers = {
              'Captcha-Key': registerForm.captchaKey,
              'Captcha-Code': registerForm.captchaCode
            }
            delete registerForm.captchaKey
            delete registerForm.captchaCode
            this.axios
              .post('/api/portal/member/register', registerForm, {
                headers
              })
              .then(res => {
                console.log(res)
                this.$message.success('注册成功')
                this.activeName = 'login'
              })
          } else {
            let url = ''
            let form = {}
            let headers = {}
            if (formName === 'loginFormAccount') {
              url = '/api/blade-auth/member/oauth/token'
              form = { ...this.loginFormAccount }
              form.password = md5(form.password)
            }
            if (formName === 'loginFormPhone') {
              url = '/api/blade-auth/member/oauth/sms/token'
              form = { ...this.loginFormPhone }
              headers = {
                'content-type': 'application/x-www-form-urlencoded',
                'Captcha-Key': form.captchaKey,
                'Captcha-Code': form.captchaCode
              }
              delete form.captchaKey
              delete form.captchaCode
            }
            this.axios
              .post(url, qs.stringify(form), {
                headers
              })
              .then(res => {
                let data = res.data
                this.$message.success('登陆成功')
                this.visible = false
                let token = data.access_token
                let username = data.user_name
                let userId = data.user_id
                localStorage.setItem('token', token)
                localStorage.setItem('userId', userId)
                localStorage.setItem('username', username)
                localStorage.setItem('memberCertStatus', data.memberCertStatus)
                this.axios.defaults.headers.common['Blade-Auth'] = token
                this.$store.commit('setUserId', userId)
                this.$store.commit('setUsername', username)
              })
          }
        } else {
          return false
        }
      })
    },
    open(type, ok) {
      this.activeName = type
      this.ok = ok
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    confirm() {
      let cancel = () => this.cancel()
      this.ok(cancel)
      this.autoClose && cancel()
    },
    beClose(done) {
      done()
      this.beforeClose()
      this.cancel()
    },
    getDialogEvents() {
      let { close } = this.config || {},
        events = {}

      if (close && typeof close == 'function') {
        Object.assign(events, {
          close
        })
      }

      return events
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
</style>
