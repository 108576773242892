<template>
  <el-dialog
    top="20vh"
    class="el-dialog-cus"
    v-bind="attributes"
    :visible="visible"
    append-to-body
    :close-on-click-modal="false"
    v-on="on"
  >
    <div class="pop-new-box">
      <div class="pop-new-bg">
        <h5 class="pop-new-tit">
          <span>发布动态</span><el-link class="custon-close" @click="cancel"><i class="el-icon-close"></i></el-link>
        </h5>
        <el-input
          class="pop-new-input"
          type="textarea"
          placeholder="请编辑动态内容"
          v-model="form.blogContent"
        ></el-input>
        <div class="pop-new-btn">
          <div class="pop-down-box">
            <el-popover v-if="videoList.length === 0" placement="bottom" width="390" trigger="click">
              <el-upload
                ref="picUpload"
                action="/api/portal/blog/auth/put-file"
                list-type="picture-card"
                multiple
                :auto-upload="true"
                :file-list="picList"
                :headers="headers"
                accept="image/*"
                :on-preview="handlePictureCardPreview"
                :on-remove="handlePicRemove"
                :on-change="handlePicChange"
              >
                <i slot="default" class="el-icon-plus"></i>
                <!-- <div slot="file" slot-scope="{ file }">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <i class="el-icon-download"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div> -->
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <el-button class="add-img-btn" slot="reference"><i class="add-img-i"></i>添加图片</el-button>
            </el-popover>

            <!-- <el-popover v-if="picList.length === 0" placement="bottom" width="390" trigger="click">
              <el-upload
                ref="videoUpload"
                action="/api/portal/blog/auth/put-file"
                list-type="picture-card"
                :auto-upload="true"
                :file-list="videoList"
                :headers="headers"
                :limit="1"
                accept="video/*"
                :on-remove="handleVideoRemove"
                :on-change="handleVideoChange"
              >
                <i slot="default" class="el-icon-plus"></i>
                <template slot="file" slot-scope="{ file }">
                  <el-progress
                    v-if="file.status === 'uploading'"
                    type="circle"
                    :percentage="file.percentage.toFixed(0)"
                    :width="88"
                    style="width: 100%;"
                  ></el-progress>
                  <template v-if="file.status === 'success'">
                    <video
                      class="el-upload-list__item-thumbnail"
                      :src="file.response.data.link"
                      preload="metadata"
                    ></video>
                    <span class="el-upload-list__item-actions">
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleVideoRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </template>
                </template>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <el-button class="add-img-btn" slot="reference"><i class="add-video-i"></i>添加视频</el-button>
            </el-popover> -->
          </div>
          <el-button class="fb-btns" @click="submit">发布</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      default: () => ({
        width: '800px',
        title: '提示',
        center: true,
        btnTxt: ['取消', '确定']
      })
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    let username = 'portal'
    let password = 'oyc8271928'
    const { config } = this,
      { btnTxt } = config
    return {
      form: {
        blogContent: '',
        blogType: null
      },
      types: 'user',
      activeName: 'first',
      visible: false,
      attributes: config,
      btnTxt,
      on: this.getDialogEvents(),
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      headers: {
        Authorization: `Basic ${btoa(`${username}:${unescape(encodeURIComponent(password))}`)}`,
        'Blade-Auth': this.axios.defaults.headers.common['Blade-Auth']
      },
      picList: [],
      videoList: []
    }
  },
  methods: {
    ...mapMutations(['setPostUpdated']),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleRemove(file) {
      console.log(file)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
    },

    open(ok) {
      this.ok = ok
      this.visible = true
    },
    cancel() {
      this.beClose()
      this.visible = false
    },
    confirm() {
      let cancel = () => this.cancel()
      this.ok(cancel)
      this.autoClose && cancel()
    },
    beClose() {
      this.beforeClose()
      if (this.$refs.picUpload) this.$refs.picUpload.clearFiles()
      if (this.$refs.videoUpload) this.$refs.videoUpload.clearFiles()
      this.form = {
        blogContent: '',
        blogType: null
      }
      this.picList = []
      this.videoList = []
    },
    getDialogEvents() {
      let { close } = this.config || {},
        events = {}

      if (close && typeof close == 'function') {
        Object.assign(events, {
          close
        })
      }

      return events
    },
    handlePicRemove(file, fileList) {
      this.picList = fileList
    },
    handleVideoRemove(file, fileList) {
      this.$refs.videoUpload.clearFiles()
      this.videoList = []
    },
    handlePicChange(file, fileList) {
      this.picList = fileList
      this.form.blogType = 1
    },
    handleVideoChange(file, fileList) {
      this.videoList = fileList.slice(-1)
      this.form.blogType = 2
    },
    submit() {
      if (!this.form.blogContent) {
        this.$message.warning('请输入动态内容')
        return
      }
      let form = {
        ...this.form,
        bolgImgs: this.picList.map(file => file.response.data.link).join(','),
        blogVideo: this.videoList.map(file => file.response.data.link).join(',')
      }
      this.axios.post('/api/portal/blog/auth/pub-content', form).then(res => {
        this.$message.success('发布成功')
        this.beClose()
        this.visible = false
        this.setPostUpdated(true)
      })
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
.el-upload-list--picture-card .el-upload-list__item {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 0px;
  box-sizing: border-box;
  width: 90px !important;
  height: 90px !important;
  margin: 0px 10px 20px 10px !important;
  display: inline-block;
}
.el-upload--picture-card {
  background-color: #ffffff !important;
  border: 1px dashed #cccccc !important;
  border-radius: 0px;
  box-sizing: border-box;
  width: 90px !important;
  height: 90px !important;
  line-height: 90px !important;
  vertical-align: top;
}
.el-popover {
  padding: 20px 25px 10px 25px !important;
  box-sizing: border-box;
  margin-top: 35px !important;
}
.el-upload--picture-card:hover,
.el-upload:focus {
  border-color: #d4a76b;
  color: #d4a76b;
}
</style>
