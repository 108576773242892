import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/reset.scss'
import './assets/scss/index.scss'
import dayjs from 'dayjs'

Vue.prototype.$dayjs = dayjs

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimePicker,
  Popover,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Alert,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Cascader,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Link,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification
} from 'element-ui'

Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Popover)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Alert)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Spinner)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Cascader)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Link)
Vue.use(CascaderPanel)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

const axiosInstance = axios.create({
  auth: {
    username: 'portal',
    password: 'oyc8271928'
  }
})

axiosInstance.interceptors.request.use(
  config => {
    if (config.method == 'get') {
      config.params = {
        ...config.params,
        _t: Date.parse(new Date()) / 1000
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

let token = localStorage.getItem('token')
let userId = localStorage.getItem('userId')
let username = localStorage.getItem('username')
let city = localStorage.getItem('city')
axiosInstance.defaults.headers.common['Blade-Auth'] = token
store.commit('setUserId', userId)
store.commit('setUsername', username)
store.commit('setCity', city)

axiosInstance.interceptors.response.use(
  response => {
    const data = response.data
    if (data.success) {
      return data
    } else {
      if (data.code === 401) {
        token = null
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('username')
        localStorage.removeItem('memberCertStatus')
        axiosInstance.defaults.headers.common['Blade-Auth'] = ''
        store.commit('setUserId', '')
        store.commit('setUsername', '')
        router.push('/')
        Message.info('登陆失效')
      } else {
        Message.error(data.msg)
      }
      const err = new Error()
      err.message = data.msg
      err.response = response
      throw err
    }
  },
  error => {
    if (error.message && error.message.includes('timeout')) {
      Message.error('网络连接超时')
    }
    let response = error.response
    if (response) {
      switch (response.status) {
        case 400:
          Message.error(response.data.msg)
          break
        case 401:
          token = null
          localStorage.removeItem('token')
          localStorage.removeItem('userId')
          localStorage.removeItem('username')
          localStorage.removeItem('memberCertStatus')
          axiosInstance.defaults.headers.common['Blade-Auth'] = ''
          store.commit('setUserId', '')
          store.commit('setUsername', '')
          router.push('/')
          Message.info('登陆失效')
          break
        case 403:
          Message.error('禁止访问')
          break
        case 404:
          Message.error(`地址未找到`)
          break
        case 405:
          Message.error(`方法不被允许`)
          break
        case 408:
          Message.error('请求超时')
          break
        case 500:
          Message.error('服务器内部错误')
          break
        default:
      }
    }
    return Promise.reject(error.response)
  }
)

Vue.use(VueAxios, axiosInstance)

function getBrowser() {
  let userAgent = navigator.userAgent
  let isOpera = userAgent.indexOf('Opera') > -1
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera
  let isEdge = userAgent.indexOf('Edge') > -1
  let isFF = userAgent.indexOf('Firefox') > -1
  let isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') == -1
  let isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1

  if (isIE) {
    return 'IE'
  }
  if (isOpera) {
    return 'Opera'
  }
  if (isEdge) {
    return 'Edge'
  }
  if (isFF) {
    return 'Firefox'
  }
  if (isSafari) {
    return 'Safari'
  }
  if (isChrome) {
    return 'Chrome'
  }
}

let pv = {
  clientType: 1,
  deviceType: 'PC',
  browser: getBrowser(),
  os: navigator.platform,
  timeZone: new Date().getTimezoneOffset() / 60
}

let accessModuleMap = {
  cityMap: 10,
  cityIndex: 10,
  informationNew: 11,
  regulations: 12,
  contentArticle: 121,
  projectIndex: 122,
  inheritorIndex: 123,
  myNew: 13
}

router.beforeEach((to, from, next) => {
  if (to.path === '/cityMap') {
    localStorage.removeItem('city')
    store.commit('setCity', '')
  }
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    let head = document.getElementsByTagName('head')
    let meta = document.createElement('meta')
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (token) {
    axiosInstance.get('/api/portal/blog/auth/list-my-materials')
  }
  next()
})

router.afterEach((to, from) => {
  let pvParams = {
    ...pv,
    ...{
      accessModule: accessModuleMap[to.name] || 14, // other 13
      referrer: document.referer,
      url: window.location.href
    }
  }
  let contentId = to.query.contentId
  if (contentId) pvParams.objectId = contentId
  else if (to.name == 'cityMap') pvParams.objectId = '1336642559600742401'
  else if (to.name == 'cityIndex') pvParams.objectId = to.query.id
  axiosInstance.get('/api/portal/site/pv-submit', {
    params: pvParams
  })
})

let mixin = {
  filters: {
    //格式化日期的过滤器
    dateFormat(value, formatType = 'YYYY-MM-DD') {
      if (!value) {
        return ''
      } else {
        return dayjs(value).format(formatType)
      }
    }
  }
}
//全局混入
Vue.mixin(mixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
