<template>
  <div class="index-box">
    <head-main></head-main>
    <div class="carousel-box">
      <div class="search-box">
        <el-autocomplete
          popper-class="my-autocomplete"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <span class="search-span" slot="suffix" @click="toSearchResult">
            <i class="el-icon-search el-input__icon"> </i>搜索
          </span>

          <template class="search-boxs" slot-scope="{ item }">
            <div class="name">{{ item.contentName }}</div>
          </template>
        </el-autocomplete>
      </div>
      <!-- "`el-icon-${item.icon}`" -->
      <el-carousel :interval="10000" arrow="never" :height="screenHeight + 'px'">
        <el-carousel-item
          v-for="(item, index) in bannerList"
          :key="index"
          :style="{
            backgroundImage: 'url(' + item.bannerImg + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }"
        >
          <!-- <h3>{{ index }}</h3> -->
        </el-carousel-item>
      </el-carousel>
    </div>

    <botto-com></botto-com>
  </div>
</template>
<script>
import HeadMain from '../components/head'
import BottoCom from '../components/bottom'

export default {
  components: {
    HeadMain,
    BottoCom
  },
  data() {
    return {
      restaurants: [],
      state: '',
      screenHeight: document.body.clientHeight,
      bannerList: [
        /* { bannerImg: require('../assets/images/index-bg.jpg') },
        { bannerImg: require('../assets/images/index-bg1.jpg') }, */
		{ bannerImg: '' },
		{ bannerImg: '' }
      ]
    }
  },
  methods: {
	 initBackImg(){
		this.axios.get('/api/portal/cont/getImgs',{params:{clientType:1}}).then(res=>{
			
			this.bannerList[0].bannerImg=res.data[0].imgUrl
			this.bannerList[1].bannerImg=res.data[1].imgUrl
		}) 
	 },
    querySearch(queryString, cb) {
      if (queryString) {
        this.axios.get('/api/portal/cont/pub-search', { params: { contentName: queryString } }).then(res => {
          cb(res.data.records)
        })
      } else {
        cb([])
      }
    },
    handleSelect({ contentUrl, id, contentId }) {
      this.$router.push({ path: contentUrl, query: { id, contentId } })
    },
    toSearchResult(ev) {
      if (!this.state) {
        this.$message.warning('请输入内容')
        return
      }
      this.$router.push(`/searchResult/${this.state}`)
    }
  },


  mounted() {
	  this.initBackImg();
    document.getElementsByTagName('body')[0].className = 'equipment-body'
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight
        that.screenHeight = window.screenHeight
      })()
    }
  },

  beforeDestroy() {
    document.body.removeAttribute('class', 'equipment-body')
  },

  watch: {
    screenHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenHeight = val
        this.timer = true
        let that = this
        setTimeout(function() {
          // 打印screenWidth变化的值
          console.log(that.screenHeight)
          that.timer = false
        }, 400)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
