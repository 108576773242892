<template>
  <div class="custon-head">
    <div class="logo-bg">
      <h5 class="head-tit">南平市非物质文化遗产保护中心</h5>
      <i class="logo"></i>
    </div>
    <div class="head-date-info">
      <div class="head-l">
        <span v-for="(item, index) in calendar.split(' ')" :key="index">{{ item }}</span>
        <!-- <span>2020年10月10日</span> -->
        <!-- <span>星期六</span> -->
        <!-- <span>农历八月二十四</span> -->
      </div>
      <div class="head-r">
        <el-dropdown class="city-change">
          <span class="el-dropdown-link"> {{ cityName }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(city, index) in citys"
              :key="city.id"
              @click.native="handleMenuClick(city, 'city', index)"
            >
              {{ city.columnName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>你好</span>
        <div class="no-login" v-if="!username">
          <span class="head-link" @click="open('login')" style="margin-right: 0">登录</span>
          <i class="line-x">/</i>
          <span class="head-link" @click="open('register')" style="margin-right: 0">注册</span>
        </div>
        <div class="has-login" v-else-if="username">
          <el-dropdown placement="bottom">
            <span class="el-dropdown-link">
              {{ username }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toPersonCenter">个人中心</el-dropdown-item>
              <el-dropdown-item v-if="memberCertStatus == 2" @click.native="news">发布动态</el-dropdown-item>
              <!-- <el-dropdown-item @click.native="toMyInfo">设置资料</el-dropdown-item> -->
              <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <span class="mail-icon" @click="toMail"><i class="i-red"></i></span> -->
        </div>
      </div>
    </div>
    <div class="custon-main">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <template v-for="(menu, index) in menus">
          <!-- <el-submenu v-if="index === 0" :key="menu.id" :index="menu.columnUrl + '?id=' + menu.id">
            <template slot="title">{{ menu.columnName }}</template>
            <el-menu-item
              v-for="(city, index) in citys"
              :key="city.id"
              :index="menu.columnUrl + (index === 0 ? '' : '?id=' + city.id)"
              @click="handleMenuClick(menu, city, index)"
            >
              {{ city.columnName }}
            </el-menu-item>
          </el-submenu> -->
          <el-menu-item
            :key="menu.id"
            :index="menu.columnUrl + ($route.path === '/cityMap' ? '' : '?id=' + menu.id)"
            :class="{ 'm-l': index === 3 }"
            @click="handleMenuClick(menu)"
          >
            {{ menu.columnName }}
          </el-menu-item>
        </template>
      </el-menu>
    </div>
    <poplogin ref="loginDialog" :config="config"></poplogin>
    <pop-news ref="popNews"></pop-news>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// import md5 from 'js-md5'
// md5(pwd)
import poplogin from '../components/poplogin'
import popNews from '../components/popNews'

export default {
  components: {
    poplogin,
    popNews
  },
  props: {
    type: {
      type: String,
      default: 'true'
    }
  },
  computed: {
    ...mapState(['username', 'menus', 'citys', 'city', 'calendar']),
    activeIndex() {
      let id = this.$route.query.id
      let path = this.$route.path
      return path + (path === '/cityMap' ? '' : '?id=' + id)
    },
    cityName() {
      if (this.citys.length > 0) {
        let cityObj = this.citys.find(city => city.id === this.city)
        return cityObj ? cityObj.columnName : this.citys[0].columnName
      } else {
        return ''
      }
    }
  },
  watch: {
    username: {
      immediate: true,
      deep: true,
      handler() {
        let memberCertStatus = localStorage.getItem('memberCertStatus')
        this.memberCertStatus = memberCertStatus
      }
    }
  },
  data() {
    return {
      memberCertStatus: null,
      config: {
        width: '450px',
        // title: '温馨提示',
        center: true
        // btnTxt: ['取消', '打开内层'],
      }
    }
  },
  mounted() {
    if (this.menus.length === 0) {
      this.getMenus()
    }
    if (this.citys.length === 0) {
      this.axios.get('/api/portal/site/map', {}).then(res => {
        this.setCitys(res.data)
      })
    }
  },
  methods: {
    ...mapMutations(['setUsername', 'setMenus', 'setCitys', 'setCity']),
    getMenus(cityCode) {
      return new Promise((resolve, reject) => {
        let city = cityCode || this.city
        let query = city ? '?columnId=' + city : ''
        this.axios.get('/api/portal/site/menu' + query, {}).then(res => {
          let data = res.data
          this.setMenus(data)
          resolve(data)
        })
      })
    },
    handleMenuClick(menu, city, index) {
      if (menu.isOutLink === 2) {
        window.open(menu.columnUrl)
        return
      }
      // let indexId = city ? city.id : menu.id
      let query = { id: menu.id }
      if (city) {
        let city = menu.id
        localStorage.setItem('city', city)
        if (index === 0) {
          localStorage.removeItem('city')
        }
        this.toHome(city)
      } else {
        this.$router.push({ path: menu.columnUrl, query })
      }
    },
    toHome(cityId) {
      this.getMenus(cityId).then(([{ columnUrl, id }, ...otherList]) => {
        this.setCity(cityId)
        if (this.$route.query.id == id) return
        this.$router.push({ path: columnUrl, query: { id } })
      })
    },
    open(type) {
      this.$refs.loginDialog.open(type, cancel => {
        // cancel();
        // console.log('点击提交按钮了')
      })
    },
    news() {
      this.$refs.popNews.open(cancel => {
        // cancel();
        // console.log('点击提交按钮了')
      })
    },
    toPersonCenter() {
      this.$router.push('/myNew')
    },
    toMyInfo() {
      this.$router.push('/myinformation')
    },
    toMail() {
      this.$router.push('/message')
    },
    logout() {
      this.axios.get('/api/blade-auth/member/oauth/logout').then(res => {
        this.$message.success('注销成功')
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('memberCertStatus')
        this.setUsername('')
        this.axios.defaults.headers.common['Blade-Auth'] = ''
        this.$router.push('/')
        this.$refs.loginDialog.reset()
      })
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'fz';
  src: url('~@/assets/fonts/FZQKBYSJW.TTF');
}
.el-menu--horizontal {
  top: 140px !important;
  .el-menu--popup {
    min-width: 150px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    position: relative;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -5px;
      margin-top: -10px;
    }
    li {
      font-family: 'fz';
      font-size: 16px;
      background: transparent !important;
      text-align: center;
      color: #ffffff;
      &:hover {
        color: #d4a76b !important;
      }
    }
    .is-active {
      color: #d4a76b !important;
    }
  }
}
.el-dropdown {
  cursor: pointer;
}

.city-change {
  margin-left: 15px;
}
.el-dropdown-menu {
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
  border: 0px solid #ebeef5 !important;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 50%;
    margin-left: -6px;
    top: -6px;
  }
}
.el-dropdown-menu__item {
  font-family: 'fz';
  font-size: 16px;
  background: transparent !important;
  text-align: center;
  color: #ffffff;
  &:hover {
    color: #d4a76b !important;
  }
}
.el-dropdown-menu__item {
  list-style: none;
  line-height: 36px !important;
  padding: 0 30px !important;
  margin: 0 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  &:hover {
    color: #ffd7a1 !important;
    background: transparent !important;
  }
}
.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none !important;
}
</style>
