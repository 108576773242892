<template>
  <el-dialog
    top="20vh"
    class="el-dialog-cus"
    v-bind="attributes"
    :visible="visible"
    :before-close="beClose"
    append-to-body
    :close-on-click-modal="false"
    v-on="on"
  >
    <div class="pop-login-box">
      <el-link class="custon-close" @click="cancel"><i class="el-icon-close"></i></el-link>
      <div class="info-form">
        <div class="login-tab">
          <el-tabs v-model="activeName">
            <el-tab-pane label="忘记密码" name="pwd">
              <el-form :model="pwdForm" :rules="pwdFormRules" ref="pwdForm" label-width="100px" class="zc-tbl">
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="pwdForm.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="captchaCode">
                  <el-input v-model="pwdForm.captchaCode" placeholder="请输入验证码">
                    <el-button slot="suffix" class="message-btn" @click="getCaptcha" :disabled="countdown !== 0">
                      <template v-if="countdown === 0">发送验证码</template>
                      <template v-else>{{ countdown }}s后重新发送</template>
                    </el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="设置密码" prop="password">
                  <el-input v-model="pwdForm.password" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
              </el-form>
              <el-button class="form-btn" @click="submitForm('pwdForm')">立即修改</el-button>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import md5 from 'js-md5'

export default {
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      default: () => ({
        width: '420px',
        title: '提示',
        center: true,
        btnTxt: ['取消', '确定']
      })
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      countdown: 0,
      wx: require('../assets/images/wx-m.png'),
      activeName: 'pwd',
      visible: false,
      attributes: this.config,
      btnTxt: this.config.btnTxt,
      on: this.getDialogEvents(),
      pwdForm: {
        phone: '',
        password: '',
        captchaKey: '',
        captchaCode: ''
      },
      pwdFormRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        captchaCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getCaptcha() {
      this.axios.get('/api/blade-auth/member/oauth/sms?mobile=' + this.pwdForm.phone).then(res => {
        this.$message.success('发送成功')
        this.pwdForm.captchaKey = res.data.key
        // this.pwdForm.captchaCode = res.data.code
        this.countdown = 60
        let timer = setInterval(() => {
          this.countdown--
          if (this.countdown === 0) {
            clearInterval(timer)
          }
        }, 1000)
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let pwdForm = { ...this.pwdForm }
          pwdForm.password = md5(pwdForm.password)
          let headers = {
            'Captcha-Key': pwdForm.captchaKey,
            'Captcha-Code': pwdForm.captchaCode
          }
          delete pwdForm.captchaKey
          delete pwdForm.captchaCode
          this.axios
            .post('/api/portal/member/forget/password', pwdForm, {
              headers
            })
            .then(res => {
              console.log(res)
              this.$message.success('修改成功')
              this.visible = false
            })
        } else {
          return false
        }
      })
    },
    open(type, ok) {
      this.pwdForm = {
        phone: '',
        password: '',
        captchaKey: '',
        captchaCode: ''
      }
      this.ok = ok
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    confirm() {
      let cancel = () => this.cancel()
      this.ok(cancel)
      this.autoClose && cancel()
    },
    beClose(done) {
      done()
      this.beforeClose()
      this.cancel()
    },
    getDialogEvents() {
      let { close } = this.config || {},
        events = {}

      if (close && typeof close == 'function') {
        Object.assign(events, {
          close
        })
      }

      return events
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
</style>
